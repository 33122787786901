/* eslint-disable */
import React from 'react'
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";


class Promoted extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {

    return (
      <div>
          <div class="flex-promoted">
              <div class="third">
                  <img src="" alt="" />
                  <div class="blue-bottom">
                      <p>theme</p>
                      <h6>Name</h6>
                  </div>
              </div>
              <div class="third">
                  <img src="" alt="" />
                  <div class="blue-bottom">
                      <p>theme</p>
                      <h6>Name</h6>
                  </div>
              </div>
              <div class="third">
                  <img src="" alt="" />
                  <div class="blue-bottom">
                      <p>theme</p>
                      <h6>Name</h6>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

export default Promoted

// export const query = graphql`
// query {
//
// }
// `
